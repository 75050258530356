import React, { useEffect, useRef, useState } from "react";

interface Props {}

const StrikeThru: React.FC<Props> = ({ children }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(50);

  useEffect(() => {
    setHeight(divRef.current.clientHeight);
  }, []);

  return (
    <div ref={divRef} style={{ position: "relative" }}>
      {children}
      <div
        style={{
          position: "absolute",
          height: 3,
          top: height / 2,
          width: "100%",
          transform: "rotate(-15deg)",
          backgroundColor: "red",
        }}
      ></div>
    </div>
  );
};

export default StrikeThru;
