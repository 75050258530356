import { P } from "../general/Typography";
import React, { useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import { NavContext } from "../../contexts/NavContext";
import Box from "../general/Box";
import Flex from "../general/Flex";
import BannerBackground from "./BannerBackground";
import HomeBannerTitle from "./HomeBannerTitle";
import Button from "../general/Button";
import useViewPort from "../../hooks/useViewPort";
import { useTheme } from "styled-components";
import styled from "styled-components";
import { CheckIcon, MarkerIcon, TelephoneIcon } from "../general/Icon";

const ResponsiveP = styled(P)`
  @media (min-width: ${({ theme }) => theme.breakPointLg}px) {
    width: 300px;
  }
`;

interface Props {}

const HomeBanner: React.FC<Props> = () => {
  const { headerHeight } = useContext(NavContext);
  const { breakPointMd } = useTheme() as any;

  const { height, width } = useViewPort();

  const minBannerHeight = 600;
  const bannerHeight = height - headerHeight;

  let calculatedHeight: number | string =
    bannerHeight < minBannerHeight ? minBannerHeight : bannerHeight;

  if (width > breakPointMd) {
    calculatedHeight = "auto";
  }

  return (
    <BannerBackground height={calculatedHeight}>
      <Container className="h-100">
        <Row className="h-100">
          <Col xs="12" className="h-100">
            <Flex h="100" pt="5" flex-column justify-content="between">
              <Box pt="5" mt="4">
                <HomeBannerTitle />
              </Box>

              <Box
                d-lg-flex
                justify-content-lg-between
                text-center
                my="5"
                pt-md="4"
                pt-lg="5"
                pb-md="3"
              >
                <ResponsiveP bold fontSize="medium" color="white">
                  <CheckIcon className="mr-2" />
                  Volledig pakket vanaf €1299
                </ResponsiveP>
                <ResponsiveP
                  bold
                  fontSize="medium"
                  color="white"
                  title="Leiden | Warmond | Katwijk | Wassenaar | Noordwijk | Voorschoten | Oegstgeest | Noordwijkerhout | Voorhout"
                >
                  <MarkerIcon className="mr-2" />
                  Leiden, Warmond, Katwijk en omstreken
                </ResponsiveP>
                <ResponsiveP bold fontSize="medium" color="white">
                  <TelephoneIcon className="mr-2" />
                  0647079774
                </ResponsiveP>
              </Box>

              <Flex justify-content="center" mb="5" pb="3">
                <Button to="/contact?message=Hoi Amar, <br><br>Ik wil graag een gratis proefles aanvragen!">
                  Gratis Proefles!
                </Button>
              </Flex>
            </Flex>
          </Col>
        </Row>
      </Container>
    </BannerBackground>
  );
};

export default HomeBanner;
