import React from "react";
import { Col, Container, Row } from "reactstrap";
import ActiePakket from "../components/general/ActiePakket";
import Box from "../components/general/Box";
import { H2, P, Span } from "../components/general/Typography";
import HomeBanner from "../components/home/HomeBanner";
import Layout from "../components/layout/Layout";
import Button from "../components/general/Button";
import Reviews from "../components/home/Reviews";
import Flex from "../components/general/Flex";
import DuimpjesGroup from "../components/home/DuimpjesGroup";
import Head from "../components/general/Head";

interface Props {}

const index: React.FC<Props> = () => {
  return (
    <Layout>
      <Head page="home" />
      <HomeBanner />

      <Container>
        <Row>
          <Col xs="12" md={{ size: 10, offset: 1 }}>
            <Box text-center py="4" py-md="5">
              <H2 color="blue" fontSize="xxl" className="mb-4">
                Rijschool Lamar
              </H2>
              <H2 fontSize="xxl" color="blue">
                De beste rijschool in Leiden en omstreken!
              </H2>
            </Box>

            <Row>
              <Col xs="12" md={{ size: 10, offset: 1 }}>
                <Box mb="5" text-center>
                  <P light>
                    Rijles nemen is natuurlijk altijd een beetje spannend. Je
                    leert een compleet nieuwe vaardigheid, waarbij je al je
                    zintuigen moet gebruiken. Rijschool Lamar helpt je hierbij.
                    Rijschool Lamar zorgt ervoor dat je vol zelfvertrouwen de
                    (snel)weg verovert!
                  </P>

                  <P light>
                    Wil je dan ook snel, veilig en goedkoop je rijbewijs halen
                    in Leiden en omstreken? Rijschool Lamar biedt scherpe
                    prijzen zodat je aan het eind van de rit zelfs nog geld
                    overhoudt voor je eerste eigen auto!
                  </P>
                </Box>
              </Col>
            </Row>

            <Flex justify-content="center" my="2">
              <ActiePakket />
            </Flex>
            <Box my="5" pt="3" pb="3" pb-md="4" text-center>
              <Button to="/contact?message=Hoi Amar, <br><br>Ik wil graag een afspraak maken!">
                Maak nu een afspraak!
              </Button>
            </Box>
          </Col>
        </Row>
      </Container>

      <Reviews />

      <Container>
        <Row>
          <Col xs={{ size: 10, offset: 1 }}>
            <Box my="4" my-lg="5">
              <DuimpjesGroup />
            </Box>
          </Col>

          <Col xs="12" md={{ size: 10, offset: 1 }}>
            <Box text-center>
              <P light>
                Een vertrouwde methode die het mogelijk maakt om je rijbewijs in
                één keer te behalen, dat vind je bij Rijschool Lamar! Rijschool
                Lamar is een ervaren rijschool die les geeft aan alle
                leeftijdscategorieën
              </P>
              <P light>
                Rijschool Lamar geeft niet alleen in het Nederlands maar ook ook
                in het <Span bold>Arabisch les</Span>. Kun je je beter
                uitdrukken in het Arabisch? Kies dan voor lessen in het
                Arabisch. Verder kun je bij rijschool Lamar ook terecht als je
                last hebt van <Span bold>faalangst, ADHD of autisme</Span>. Last
                but not least, wanneer je alleen{" "}
                <Span bold>les wilt hebben van een vrouw</Span>, is dat
                mogelijk. We zijn echt rij-specialisten bij Rijschool Lamar en
                denken met je mee!
              </P>
            </Box>

            <Box my="5" py="3" py-md="4" text-center>
              <Button to="/contact?message=Hoi Amar, <br><br>Ik wil graag een gratis proefles aanvragen!">
                Vraag proefles aan!
              </Button>
            </Box>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default index;
