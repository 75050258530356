import React from "react";
import Box from "../general/Box";
import Flex from "../general/Flex";
import Duimpje from "../general/images/Duimpje";
import { H3 } from "../general/Typography";

interface Props {}

const DuimpjesGroup: React.FC<Props> = () => {
  return (
    <Box d-flex justify-content-center d-lg-block>
      <Box d-lg-flex justify-content-lg-between>
        <Flex justify-content="between" mb="3" align-items="center">
          <Duimpje size="big" className="mr-4" />
          <H3 fontSize="xl" color="blue">
            Faalangst
          </H3>
        </Flex>

        <Flex justify-content="between" mb="3" align-items="center">
          <Duimpje size="big" className="mr-4" />
          <H3 fontSize="xl" color="blue">
            Autisme
          </H3>
        </Flex>

        <Flex justify-content="between" mb="3" align-items="center">
          <Duimpje size="big" className="mr-4" />
          <H3 className="text-right" fontSize="xl" color="blue">
            Arabische <br className="d-md-none" />
            les
          </H3>
        </Flex>
      </Box>
    </Box>
  );
};

export default DuimpjesGroup;
