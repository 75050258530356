import React from "react";
import { H1 } from "../general/Typography";
import Flex from "../general/Flex";
import styled from "styled-components";

const Smiley = styled.img`
  position: absolute;
  top: -65px;
  left: 170px;
  transform: rotate(12deg);
  height: 100px;
  z-index: -1;
  @media (min-width: ${({ theme }) => theme.breakPointSm}px) {
    left: auto;
    right: 60px;
  }
  @media (min-width: ${({ theme }) => theme.breakPointMd}px) {
    top: -60px;
    left: auto;
    right: 120px;
  }
  @media (min-width: ${({ theme }) => theme.breakPointLg}px) {
    right: 240px;
    top: -65px;
  }
  @media (min-width: ${({ theme }) => theme.breakPointXl}px) {
    right: 330px;
  }
`;

interface Props {}

const HomeTitle: React.FC<Props> = () => {
  return (
    <Flex
      justify-content="center"
      style={{
        transform: "rotate(-5deg)",
        position: "relative",
      }}
    >
      <Smiley src="/images/smiley-duimpjes-sm.png" />
      <H1 upcase background="white" color="blue">
        Slagen doe <br className="d-sm-none" /> je bij
        <br className="d-none d-md-block" /> Rijschool Lamar Leiden!
      </H1>
    </Flex>
  );
};

export default HomeTitle;
