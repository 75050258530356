import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import Box from "../general/Box";
import { DotIcon } from "../general/Icon";
import { H1, P } from "../general/Typography";
import BannerBackground from "./BannerBackground";

// Import Swiper styles
import "swiper/swiper.scss";

interface Props { }

const reviews = [
  "Geen andere woorden dan een top rijschool! Allebei top personen met veel geduld en die je erg motiveren. <br /><br />Nogmaals erg bedankt voor alles!",
  "Fantastisch instucteurs <br/><br/>Top instructor! Zeker aan te bevelen!",
  "Fijne rijschool! Amar en Rima willen het beste voor je! ",
];

const Reviews: React.FC<Props> = () => {
  const [selectedReview, setSelectedReview] = useState(0);

  const swiperRef = useRef<any>(null);
  const intervalRef = useRef<number>(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (swiperRef.current.activeIndex === reviews.length - 1) {
        for (let i = 0; i < reviews.length - 1; i++) {
          swiperRef.current.slidePrev();
        }
      } else {
        swiperRef.current.slideNext();
      }
    }, 4000);
  }, []);

  useEffect(() => {
    const slideIndex = swiperRef.current.activeIndex;

    if (slideIndex !== selectedReview) {
      const diff = Math.abs(slideIndex - selectedReview);
      const isFurther = selectedReview > slideIndex;

      if (isFurther) {
        for (let i = 0; i < diff; i++) {
          swiperRef.current.slideNext();
        }
      } else {
        for (let i = 0; i < diff; i++) {
          swiperRef.current.slidePrev();
        }
      }
    }
  }, [selectedReview]);

  const handleTouch = () => {
    clearInterval(intervalRef.current);
  };

  const handleSwipe = (e: any) => {
    setSelectedReview(e.activeIndex);
  };

  const onClickDotIcon = (index: number) => {
    clearInterval(intervalRef.current);
    setSelectedReview(index);
  };

  return (
    <BannerBackground height="auto">
      <Container>
        <Row>
          <Col xs="12">
            <Box my="5" pt="4" pt-md="5">
              <H1
                style={{ textAlign: "center" }}
                upcase
                background="white"
                color="blue"
              >
                Wat zeggen onze geslaagden?
              </H1>
            </Box>
          </Col>
        </Row>
      </Container>

      <Box my="5" text-center>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          onSlideChange={handleSwipe}
          onSwiper={swiper => {
            swiperRef.current = swiper;
          }}
        >
          {reviews.map(review => (
            <SwiperSlide onTouchStart={handleTouch}>
              <Box px="3">
                <P bold fontSize="medium" color="white">
                  <span dangerouslySetInnerHTML={{ __html: review }} />
                </P>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      <Box pb="5" text-center>
        {reviews.map((_, i) => (
          <DotIcon
            key={i}
            onClick={() => onClickDotIcon(i)}
            color="white"
            style={{ opacity: i === selectedReview ? 1 : 0.3 }}
          />
        ))}
      </Box>
    </BannerBackground>
  );
};

export default Reviews;
